import { Redirect, Route, Switch } from 'react-router';
import { AccessibleLoader } from '../../../../_shared/components/AccessibleLoader.tsx';
import { RedirectIf } from '../../../../_shared/components/routing/RedirectIf.tsx';
import { useRedirectPropsWithSameSearch } from '../../../../_shared/components/routing/useRedirectPropsWithSameSearch.tsx';
import { AppNames } from '../../../../_shared/constants/applicationNames.ts';
import {
  AccessDeniedToAssetRoute,
  AssetListingRoute,
  AssetRoute,
  AssetRouteParams,
  AssetsRoute,
  ContentItemAccessDeniedRoute,
  ContentItemAccessDeniedRouteParams,
  ContentItemRoute,
  ContentItemsAppEntryRouteParams,
  ContentItemsAppRoute,
  ContentItemsRoute,
  RelationsRoute,
  RootRoute,
} from '../../../../_shared/constants/routePaths.ts';
import { EnsureSelectedVariantIdInRoute } from '../../../../_shared/containers/EnsureSelectedVariantIdInRoute.tsx';
import { AuthorizedSection } from '../../../../_shared/containers/routing/AuthorizedSection.tsx';
import {
  IRouteContext,
  RouteContext,
} from '../../../../_shared/containers/routing/RouteContext.tsx';
import { getSelectedLanguageId } from '../../../../_shared/selectors/getSelectedLanguageId.ts';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { AccessDenied } from '../../../errorHandling/components/AccessDenied.tsx';
import { AccessDeniedToContentItemAction } from '../../../errorHandling/constants/AccessDeniedToContentItemAction.ts';
import { AccessDeniedToContentItem } from '../../../errorHandling/containers/AccessDeniedToContentItem.tsx';
import { ContentItemEditing } from '../../../itemEditor/components/ContentItemEditing.tsx';
import { RedirectIfLivePreviewNotEnabled } from '../../../itemEditor/containers/RedirectIfLivePreviewNotEnabled.tsx';
import { RedirectIfSpaceIdInRouteNotValid } from '../../../itemEditor/containers/RedirectIfSpaceIdInRouteNotValid.tsx';
import { ContentItemEditorTracker } from '../../../itemEditor/features/ContentItemEditing/containers/ContentItemEditorTracker.tsx';
import { ItemEditingLayout } from '../../../itemEditor/features/ContentItemEditing/containers/ItemEditingLayout.tsx';
import { NavigationTreeContextProviderContainer } from '../../../itemEditor/features/ContentItemEditing/features/contentItemPreview/containers/NavigationTreeContextProviderContainer.tsx';
import { RootItemProviderContainer } from '../../../itemEditor/features/ContentItemEditing/features/contentItemPreview/containers/RootItemProviderContainer.tsx';
import { RevisionViewer } from '../../../itemEditor/features/Revisions/containers/RevisionViewer.tsx';
import { RevisionsContextProvider } from '../../../itemEditor/features/Revisions/context/RevisionsContext.tsx';
import { AssetEditingPage } from '../../assets/containers/AssetEditing/AssetEditingPage.tsx';
import { AssetLibrarySelection } from '../../assets/containers/AssetLibrarySelection.tsx';
import { AssetListing } from '../../assets/containers/AssetListing/AssetListing.tsx';
import { EnsureAssetsState } from '../../assets/containers/EnsureAssetsState.tsx';
import { AssetQueryOrigin } from '../../assets/utils/assetLibraryQueryUtils.ts';
import { ContentItemInventory } from '../../content/features/ContentItemInventory/components/ContentItemInventory.tsx';
import { ensureSelectedVariantForRelationsTab } from '../../relations/actions/thunkRelationsActions.ts';
import { EnsureRelationsInitialized } from '../../relations/containers/EnsureRelationsInitialized.tsx';
import { RelationsListing } from '../../relations/containers/RelationsListing.tsx';
import { localizedRouteLeft } from '../actions/contentInventoryActions.ts';
import { ensureSelectedVariantForInventory } from '../actions/thunkContentInventoryActions.ts';
import {
  assetsRequiredCapabilities,
  contentRequiredCapabilities,
  relationsRequiredCapabilities,
} from '../utils/contentInventoryRequiredCapabilities.ts';

type Props = {
  readonly indexRoute: string;
  readonly rootAssetFolderRoute: Uuid;
};

export const ContentInventory = (props: Props) => {
  const getRedirectPropsWithSameSearch = useRedirectPropsWithSameSearch();

  return (
    <div className="canvas" {...getDataUiAppNameAttribute(DataUiAppName.ContentInventory)}>
      <Switch>
        <Route path={RelationsRoute}>
          <RouteContext>
            {(routeContextProps) => (
              <AuthorizedSection
                appName={AppNames.Relations}
                requiresOneOfCapabilities={relationsRequiredCapabilities}
              >
                <EnsureSelectedVariantIdInRoute
                  key={`${routeContextProps.match.params.projectId}${routeContextProps.match.params.variantId}ensureSelectedVariantForRelationsTab`}
                  currentRouteVariantId={routeContextProps.match.params.variantId}
                  ensureSelectedVariant={ensureSelectedVariantForRelationsTab}
                  getSelectedLanguageIdFromStore={getSelectedLanguageId}
                  localizedRouteLeft={localizedRouteLeft}
                >
                  <EnsureRelationsInitialized>
                    <RelationsListing />
                  </EnsureRelationsInitialized>
                </EnsureSelectedVariantIdInRoute>
              </AuthorizedSection>
            )}
          </RouteContext>
        </Route>
        <Route path={AssetsRoute}>
          <AuthorizedSection
            appName={AppNames.Assets}
            requiresOneOfCapabilities={assetsRequiredCapabilities}
          >
            <EnsureAssetsState origin={AssetQueryOrigin.AssetLibrary}>
              <AssetLibrarySelection>
                <Switch>
                  <Route exact path={AssetListingRoute}>
                    <AssetListing />
                  </Route>
                  <Route exact path={AssetRoute}>
                    <RouteContext>
                      {(assetRouteProps: IRouteContext<AssetRouteParams>) => (
                        <AssetEditingPage
                          key={`${assetRouteProps.match.params.projectId}${assetRouteProps.match.params.assetId}`}
                          assetId={assetRouteProps.match.params.assetId}
                        />
                      )}
                    </RouteContext>
                  </Route>
                  <Route path={AccessDeniedToAssetRoute}>
                    <AccessDenied
                      title="Do you need to see this asset?"
                      descriptionParagraphs={[
                        'You can’t view this asset.',
                        'Contact your project manager for more information.',
                      ]}
                    />
                  </Route>
                  <Route>
                    <Redirect
                      {...getRedirectPropsWithSameSearch({ to: props.rootAssetFolderRoute })}
                    />
                  </Route>
                </Switch>
              </AssetLibrarySelection>
            </EnsureAssetsState>
          </AuthorizedSection>
        </Route>
        <Route path={ContentItemsAppRoute}>
          <RouteContext>
            {(routeContext: IRouteContext<ContentItemsAppEntryRouteParams>) => (
              <AuthorizedSection
                appName={AppNames.ContentItems}
                requiresOneOfCapabilities={contentRequiredCapabilities}
              >
                <EnsureSelectedVariantIdInRoute
                  key={`${routeContext.match.params.projectId}${routeContext.match.params.variantId}ensureSelectedVariantForInventory`}
                  currentRouteVariantId={routeContext.match.params.variantId}
                  ensureSelectedVariant={ensureSelectedVariantForInventory}
                  getSelectedLanguageIdFromStore={getSelectedLanguageId}
                  localizedRouteLeft={localizedRouteLeft}
                >
                  <Switch>
                    <Route exact path={ContentItemsRoute}>
                      <AuthorizedSection
                        appName={AppNames.ContentItems}
                        requiresOneOfCapabilities={contentRequiredCapabilities}
                      >
                        <ContentItemInventory />
                      </AuthorizedSection>
                    </Route>
                    <Route path={ContentItemAccessDeniedRoute}>
                      <RouteContext>
                        {({ match }: IRouteContext<ContentItemAccessDeniedRouteParams<string>>) => (
                          <RedirectIf
                            redirectTo={RootRoute}
                            doRedirect={
                              !Object.values(AccessDeniedToContentItemAction).includes(
                                match.params.requestedAction,
                              )
                            }
                          >
                            <AccessDeniedToContentItem
                              requestedAction={match.params.requestedAction}
                            />
                          </RedirectIf>
                        )}
                      </RouteContext>
                    </Route>
                    <Route path={ContentItemRoute}>
                      <AuthorizedSection
                        appName={AppNames.ContentItems}
                        requiresOneOfCapabilities={contentRequiredCapabilities}
                      >
                        <RedirectIfLivePreviewNotEnabled>
                          <RedirectIfSpaceIdInRouteNotValid>
                            <ContentItemEditing
                              renderEditor={() => (
                                <>
                                  <RootItemProviderContainer>
                                    <NavigationTreeContextProviderContainer>
                                      <ItemEditingLayout />
                                    </NavigationTreeContextProviderContainer>
                                  </RootItemProviderContainer>
                                  <ContentItemEditorTracker />
                                </>
                              )}
                              renderRevision={(timelineItemId) => (
                                <RevisionsContextProvider>
                                  <RevisionViewer timelineItemId={timelineItemId} />
                                </RevisionsContextProvider>
                              )}
                              renderNotInitialized={() => (
                                <AccessibleLoader screenReaderText="Loading content item editing." />
                              )}
                            />
                          </RedirectIfSpaceIdInRouteNotValid>
                        </RedirectIfLivePreviewNotEnabled>
                      </AuthorizedSection>
                    </Route>
                    <Route>
                      <Redirect {...getRedirectPropsWithSameSearch({ to: ContentItemsRoute })} />
                    </Route>
                  </Switch>
                </EnsureSelectedVariantIdInRoute>
              </AuthorizedSection>
            )}
          </RouteContext>
        </Route>
        <Route>
          <Redirect {...getRedirectPropsWithSameSearch({ to: props.indexRoute })} />
        </Route>
      </Switch>
    </div>
  );
};

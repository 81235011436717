import { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { useParams } from 'react-router';
import { ContentItemsRouteParams } from '../../../../../../../_shared/constants/routePaths.ts';
import { SpacesMap } from '../../../../../../../data/models/space/space.ts';

type RootItemContextState = Readonly<{
  rootItemId: Uuid | null;
}>;

const RootItemContext = createContext<RootItemContextState>({
  rootItemId: null,
});

type Props = PropsWithChildren &
  Readonly<{
    availableSpaces: SpacesMap;
  }>;

export const RootItemContextProvider = ({ availableSpaces, children }: Props) => {
  const spaceId = useParams<ContentItemsRouteParams>().spaceId ?? null;
  const rootItemId = getRootItemId(availableSpaces, spaceId);

  const contextState: RootItemContextState = useMemo(
    () => ({
      rootItemId,
    }),
    [rootItemId],
  );

  return <RootItemContext.Provider value={contextState}>{children}</RootItemContext.Provider>;
};

const getRootItemId = (spaces: SpacesMap, spaceId: Uuid | null): Uuid | null => {
  if (!spaceId) {
    return null;
  }

  return spaces.get(spaceId)?.webSpotlightRootItemId ?? null;
};

export const useRootItem = () => useContext(RootItemContext);

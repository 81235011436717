import { Action } from '../../../../@types/Action.type.ts';
import { INewContentItemVariantInitState } from '../../../contentInventory/content/stores/IContentAppStoreState.ts';
import {
  ContentItemEditing_Init_Aborted,
  ContentItemEditing_Init_Failed,
  ContentItemEditing_Init_Finished,
  ContentItemEditing_Init_FinishedNoVariant,
  ContentItemEditing_Init_VariantPrepared,
} from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { ContentItemEditing_NewVariant_Started } from '../../features/NewVariantDialog/constants/newVariantActionTypes.ts';

const initialState: INewContentItemVariantInitState = {
  workflowId: null,
};

export const newContentItemVariantInit = (
  state = initialState,
  action: Action,
): INewContentItemVariantInitState => {
  switch (action.type) {
    case ContentItemEditing_NewVariant_Started:
    case ContentItemEditing_Init_VariantPrepared:
      return action.payload;

    case ContentItemEditing_Init_Failed:
    case ContentItemEditing_Init_Aborted:
    case ContentItemEditing_Init_Finished:
    case ContentItemEditing_Init_FinishedNoVariant:
      return initialState;

    default:
      return state;
  }
};

import { noOperation } from '@kontent-ai/utils';
import { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { useParams } from 'react-router';
import { ContentItemsRouteParams } from '../../../../../../../_shared/constants/routePaths.ts';
import { useIsNavigationTreeOpenStorage } from '../../../../../../../localStorages/useIsNavigationTreeOpenStorage.ts';
import { IsNavigationTreeEnabledContext } from '../../../context/IsNavigationTreeEnabledContext.tsx';
import { useRootItem } from './RootItemContext.tsx';

type NavigationTreeContextState = Readonly<{
  isTreeAvailable: boolean;
  isTreeOpened: boolean;
  setIsTreeOpened: (state: boolean) => void;
}>;

const NavigationTreeContext = createContext<NavigationTreeContextState>({
  isTreeAvailable: false,
  isTreeOpened: false,
  setIsTreeOpened: noOperation,
});

type Props = PropsWithChildren &
  Readonly<{
    currentEnvironmentId: string;
  }>;

export const NavigationTreeContextProvider = ({ currentEnvironmentId, children }: Props) => {
  const { isTreeOpened, setIsTreeOpened } = useIsNavigationTreeOpenStorage(currentEnvironmentId);

  const spaceId = useParams<ContentItemsRouteParams>().spaceId ?? null;
  const { rootItemId } = useRootItem();

  const isNavigationTreeEnabled = useContext(IsNavigationTreeEnabledContext);
  const isTreeAvailable = !!spaceId && !!rootItemId && isNavigationTreeEnabled;

  const contextState: NavigationTreeContextState = useMemo(
    () => ({
      isTreeAvailable,
      isTreeOpened: isTreeAvailable && isTreeOpened,
      setIsTreeOpened: (treeState: boolean) => {
        setIsTreeOpened(treeState);
      },
    }),
    [isTreeAvailable, isTreeOpened, setIsTreeOpened],
  );

  return (
    <NavigationTreeContext.Provider value={contextState}>{children}</NavigationTreeContext.Provider>
  );
};

export const useNavigationTree = () => useContext(NavigationTreeContext);

import { InputState } from '@kontent-ai/component-library/Input';
import { ISelectItem } from '@kontent-ai/component-library/Selects';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, gridUnit, px } from '@kontent-ai/component-library/tokens';
import { notNull } from '@kontent-ai/utils';
import { forwardRef, useContext, useMemo, useState } from 'react';
import { ModalDialog } from '../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import {
  DataUiAction,
  DataUiCollection,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { Workflow } from '../../../../../data/models/workflow/Workflow.ts';
import { AiTranslationsContext } from '../../../../richText/plugins/ai/actions/TranslateContent/contexts/AiTranslationsContext.tsx';
import { MenuOption } from '../../ContentItemEditing/components/contentItemEditorNotifications/variantQuickActionsNotification/VariantQuickActionsNotificationBar.tsx';

export enum NewVariantContentSource {
  Empty = 'Empty',
  CopyFromLanguage = 'CopyFromLanguage',
  TranslateWithAi = 'TranslateWithAi',
}

type NewVariantParams = {
  selectedWorkflowId: Uuid | null;
} & (
  | {
      contentSource: NewVariantContentSource.Empty;
      sourceLanguageId: null;
    }
  | {
      contentSource:
        | NewVariantContentSource.CopyFromLanguage
        | NewVariantContentSource.TranslateWithAi;
      sourceLanguageId: Uuid;
    }
);

type ContentSourceOption = ISelectItem<ContentSourceOption> & {
  readonly value: NewVariantContentSource;
};

export type CreateNewVariant = (params: NewVariantParams) => void;

type Props = {
  readonly currentLanguageName: string;
  readonly isDismissable: boolean;
  readonly onCancel?: () => void;
  readonly onSubmit: CreateNewVariant;
  readonly sourceLanguageOptions: ReadonlyArray<MenuOption>;
  readonly workflows: ReadonlyArray<Workflow>;
};

export const NewVariantDialog = forwardRef<HTMLDivElement, Props>(
  (
    { currentLanguageName, isDismissable, workflows, onCancel, onSubmit, sourceLanguageOptions },
    ref,
  ) => {
    const workflowItems = useMemo(
      () =>
        workflows.map(({ id, name }) => ({
          id,
          label: name,
        })),
      [workflows],
    );
    const [selectedWorkflowId, setSelectedWorkflowId] = useState<Uuid | null>(
      workflowItems.length === 1 ? (workflowItems[0]?.id ?? null) : null,
    );

    const { isTranslationsFeatureEnabled } = useContext(AiTranslationsContext);

    const [contentSource, setContentSource] = useState(NewVariantContentSource.Empty);
    const contentSourceItems = [
      {
        id: 'empty',
        label: 'Empty content',
        value: NewVariantContentSource.Empty,
      },
      sourceLanguageOptions.length
        ? {
            id: 'copy',
            label: 'Copy from language',
            value: NewVariantContentSource.CopyFromLanguage,
          }
        : null,
      isTranslationsFeatureEnabled
        ? {
            id: 'translate',
            label: 'Translate with AI',
            value: NewVariantContentSource.TranslateWithAi,
          }
        : null,
    ].filter(notNull);

    const sourceLanguageItems = sourceLanguageOptions.map((option) => ({
      id: option.variantId,
      label: option.name,
    }));
    const [sourceLanguageId, setSourceLanguageId] = useState<Uuid | null>(
      sourceLanguageItems.length === 1 ? (sourceLanguageItems[0]?.id ?? null) : null,
    );

    const disabledTooltipText = getDisabledTooltipText(
      selectedWorkflowId,
      contentSource,
      sourceLanguageId,
    );

    return (
      <ModalDialog
        headline={`Create language variant in ${currentLanguageName}`}
        isDismissable={isDismissable}
        isOpen
        maxWidth={px(gridUnit * 70)}
        onClose={onCancel}
        primaryAction={{
          disabled: !!disabledTooltipText,
          onClick: () =>
            onSubmit(
              contentSource === NewVariantContentSource.Empty || !sourceLanguageId
                ? {
                    selectedWorkflowId,
                    contentSource: NewVariantContentSource.Empty,
                    sourceLanguageId: null,
                  }
                : {
                    selectedWorkflowId,
                    contentSource,
                    sourceLanguageId,
                  },
            ),
          text: 'Create variant',
          tooltipText: disabledTooltipText,
          ...getDataUiActionAttribute(DataUiAction.Confirm),
        }}
        ref={ref}
        shouldCloseOnInteractOutside={() => false}
        {...getDataUiElementAttribute(DataUiElement.NewVariantDialog)}
      >
        <Stack spacing={Spacing.XL}>
          <Stack spacing={Spacing.S}>
            <p>This item is not translated yet. Do you want to create new draft?</p>
            <p>
              You could choose whether it should be empty or prefilled with content from another
              language.
            </p>
          </Stack>
          {workflowItems.length > 1 && (
            <SingleSelect
              inputDataAttributes={getDataUiCollectionAttribute(DataUiCollection.Workflows)}
              items={workflowItems}
              label="Workflow"
              onSelectionChange={setSelectedWorkflowId}
              placeholder="Select a workflow"
            />
          )}
          <SingleSelect<ContentSourceOption>
            inputState={sourceLanguageItems.length ? InputState.Default : InputState.Disabled}
            items={contentSourceItems}
            label="Create new draft from"
            onSelectionChange={(_, option) => {
              if (option) {
                setContentSource(option.value);
              }
            }}
            selectedItemId={contentSourceItems.find((item) => item.value === contentSource)?.id}
            tooltipText={
              sourceLanguageItems.length ? undefined : 'There are no language variants available'
            }
          />
          {contentSource !== NewVariantContentSource.Empty && (
            <SingleSelect
              inputState={
                sourceLanguageItems.length === 1 ? InputState.ReadOnly : InputState.Default
              }
              items={sourceLanguageItems}
              label={
                contentSource === NewVariantContentSource.CopyFromLanguage
                  ? 'Copy content from'
                  : 'Translate from'
              }
              onSelectionChange={(languageId) => setSourceLanguageId(languageId)}
              placeholder="Select a language"
              selectedItemId={sourceLanguageId}
              tooltipText={
                sourceLanguageItems.length === 1
                  ? 'This is the only available source language'
                  : undefined
              }
            />
          )}
        </Stack>
      </ModalDialog>
    );
  },
);

NewVariantDialog.displayName = 'NewVariantDialog';

const getDisabledTooltipText = (
  selectedWorkflowId: string | null,
  contentSource: NewVariantContentSource,
  sourceLanguageId: string | null,
) => {
  if (!selectedWorkflowId) {
    return 'Select a workflow';
  }

  if (contentSource !== NewVariantContentSource.Empty && !sourceLanguageId) {
    return 'Select a language';
  }

  return undefined;
};

import {
  ContentItemPreviewRoute,
  ContentItemPreviewRouteParams,
  ContentItemRoute,
  ContentItemRouteParams,
} from '../../../../../../../_shared/constants/routePaths.ts';
import {
  buildPath,
  matchPath,
  parseContentItemIds,
} from '../../../../../../../_shared/utils/routing/routeTransitionUtils.ts';

export const getUpdatedRoute = (
  currentPath: string,
  rootItemId: Uuid | null,
  spaceId: Uuid | null,
): string | null => {
  const previewRouteMatch = matchPath<ContentItemPreviewRouteParams<Uuid>>(
    currentPath,
    ContentItemPreviewRoute,
  );
  if (previewRouteMatch) {
    return buildPath<ContentItemPreviewRouteParams<UuidArray>>(ContentItemPreviewRoute, {
      app: previewRouteMatch.app,
      projectId: previewRouteMatch.projectId,
      spaceId: spaceId ?? previewRouteMatch.spaceId,
      variantId: previewRouteMatch.variantId,
      contentItemIds: getContentItemIds(previewRouteMatch.contentItemIds, rootItemId),
    });
  }

  const contentItemRouteMatch = matchPath<ContentItemRouteParams<Uuid>>(
    currentPath,
    ContentItemRoute,
  );
  if (contentItemRouteMatch) {
    return buildPath<ContentItemRouteParams<UuidArray>>(ContentItemRoute, {
      app: contentItemRouteMatch.app,
      projectId: contentItemRouteMatch.projectId,
      spaceId: spaceId ?? contentItemRouteMatch.spaceId,
      variantId: contentItemRouteMatch.variantId,
      contentItemIds: getContentItemIds(contentItemRouteMatch.contentItemIds, rootItemId),
    });
  }

  return null;
};

function getContentItemIds(
  routeParamsContentItemIds: string,
  rootItemId: string | null,
): UuidArray {
  const contentItemIds = parseContentItemIds(routeParamsContentItemIds);
  return rootItemId && contentItemIds.length > 1
    ? [rootItemId, ...contentItemIds.slice(1)]
    : contentItemIds;
}

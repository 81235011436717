import { Action } from '../../../../../@types/Action.type.ts';
import {
  Content_Editing_AssignmentSubmittingFailed,
  Content_Editing_AssignmentSubmittingFinished,
  Content_Editing_ModalDismissed,
  Content_Editing_ModalOpened,
  Content_Editing_ModalOpenedWithProperties,
  Content_Editing_PublishContentItemVariantFailed,
  Content_Editing_PublishContentItemVariantStarted,
  Content_Editing_ScheduledPublishingFailed,
  Content_Editing_ScheduledPublishingStarted,
  Content_Editing_ScheduledUnpublishingFailed,
  Content_Editing_ScheduledUnpublishingStarted,
  Content_Editing_UnpublishContentItemVariantFailed,
  Content_Editing_UnpublishContentItemVariantStarted,
} from '../../../constants/editorActionTypes.ts';
import { ItemEditingModalDialogType } from '../../../constants/itemEditingModalDialogType.ts';
import {
  ContentEditing_CascadeAction_UndoFailed,
  ContentEditing_CascadeAction_UndoFinished,
  ContentEditing_CascadeAction_UndoStarted,
  ContentEditing_CascadeModal_PublishingFailed,
  ContentEditing_CascadeModal_PublishingFinished,
  ContentEditing_CascadeModal_PublishingStarted,
  ContentEditing_CascadeModal_SchedulingFailed,
  ContentEditing_CascadeModal_SchedulingFinished,
  ContentEditing_CascadeModal_SchedulingStarted,
} from '../../../features/CascadePublish/constants/cascadeModalActionTypes.ts';
import {
  ContentItemEditing_CodenameSaving_Failed,
  ContentItemEditing_CodenameSaving_Finished,
  ContentItemEditing_CreateTask_Finished,
  ContentItemEditing_TaskArchive_Finished,
  ContentItemEditing_UpdateTask_Finished,
} from '../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { ContentEditing_MoveItemToCollection_Started } from '../../../features/MoveToCollectionDialog/constants/moveToCollectionDialogActionTypes.ts';
import { ContentItemEditing_NewVariant_Started } from '../../../features/NewVariantDialog/constants/newVariantActionTypes.ts';
import { ItemEditingModalProperties } from '../../../types/ItemEditingModalDialogProperties.type.ts';

export type ItemEditingModalDialogState = {
  readonly type: ItemEditingModalDialogType | null;
  readonly properties: ItemEditingModalProperties | null;
};

const initialState: ItemEditingModalDialogState = {
  type: null,
  properties: null,
};

export const itemEditingModalDialog = (
  state: ItemEditingModalDialogState = initialState,
  action: Action,
): ItemEditingModalDialogState => {
  switch (action.type) {
    case Content_Editing_ModalOpened:
      return {
        type: action.payload.type,
        properties: null,
      };

    case Content_Editing_ModalOpenedWithProperties:
      return {
        type: action.payload.properties.dialogType,
        properties: action.payload.properties,
      };

    case Content_Editing_PublishContentItemVariantFailed:
    case Content_Editing_UnpublishContentItemVariantFailed:
    case Content_Editing_ScheduledPublishingFailed:
    case Content_Editing_ScheduledUnpublishingFailed:
    case Content_Editing_AssignmentSubmittingFailed:
      return {
        type: ItemEditingModalDialogType.ChangeWorkflowStepDialog,
        properties: action.payload.modalDialogProperties,
      };

    case ContentEditing_CascadeModal_PublishingFailed:
    case ContentEditing_CascadeModal_PublishingStarted:
    case ContentEditing_CascadeModal_SchedulingFailed:
    case ContentEditing_CascadeModal_SchedulingStarted:
    case ContentEditing_CascadeAction_UndoFailed:
    case ContentEditing_CascadeAction_UndoStarted:
    case ContentEditing_MoveItemToCollection_Started:
    case ContentItemEditing_NewVariant_Started:
    case Content_Editing_AssignmentSubmittingFinished:
    case ContentItemEditing_TaskArchive_Finished:
    case ContentItemEditing_UpdateTask_Finished:
    case ContentItemEditing_CreateTask_Finished:
    case ContentItemEditing_CodenameSaving_Finished:
    case ContentItemEditing_CodenameSaving_Failed:
    case Content_Editing_PublishContentItemVariantStarted:
    case Content_Editing_UnpublishContentItemVariantStarted:
    case Content_Editing_ScheduledPublishingStarted:
    case Content_Editing_ScheduledUnpublishingStarted:
    case Content_Editing_ModalDismissed: {
      return initialState;
    }

    case ContentEditing_CascadeAction_UndoFinished:
    case ContentEditing_CascadeModal_PublishingFinished: {
      return action.payload.failedItemIds.size > 0
        ? {
            ...initialState,
            type: ItemEditingModalDialogType.CascadePublishFailedItemsDialog,
          }
        : state;
    }

    case ContentEditing_CascadeModal_SchedulingFinished: {
      return action.payload.failedItemIds.size > 0
        ? {
            ...initialState,
            type: ItemEditingModalDialogType.CascadeScheduleFailedItemsDialog,
          }
        : state;
    }

    default:
      return state;
  }
};

import useLocalStorageState from 'use-local-storage-state';
import { SelectedItemEditorSpaceIdStorageKey } from '../_shared/constants/localStorageKeys.ts';

type SpaceSelectorState = Readonly<{
  spaceId: Uuid | null;
  setSpaceId: (value: Uuid) => void;
}>;

const getStorageKey = (environmentId: Uuid): string => {
  return `${SelectedItemEditorSpaceIdStorageKey}_${environmentId}`;
};

export const useSelectedItemEditorSpaceIdStorage = (environmentId: Uuid): SpaceSelectorState => {
  const storageKey = getStorageKey(environmentId);

  const [spaceId, setSpaceId] = useLocalStorageState<Uuid | null>(storageKey, {
    defaultValue: null,
    storageSync: false,
  });

  return {
    spaceId,
    setSpaceId,
  };
};

import { combineReducers } from '@reduxjs/toolkit';
import { changeWorkflowStepModalData } from '../../../../_shared/features/ChangeWorkflowStepModal/reducers/changeWorkflowStepModalData.ts';
import { listingUi } from '../../../contentInventory/content/reducers/listingUi/listingUi.ts';
import { newContentItem } from '../../features/NewContentItem/reducers/newContentItem.ts';
import { areContentItemElementsHighlighting } from '../internalReducers/areContentItemElementsHighlighting.ts';
import { compareRevisions } from '../internalReducers/compareRevisions.ts';
import { contentItemVariants } from '../internalReducers/contentItemVariants.ts';
import { defaultVariantOverwritten } from '../internalReducers/defaultVariantOverwritten.ts';
import { editedContentItem } from '../internalReducers/editedContentItem.ts';
import { editedContentItemOverwritten } from '../internalReducers/editedContentItemOverwritten.ts';
import { editedContentItemStatus } from '../internalReducers/editedContentItemStatus.ts';
import { editedContentItemVariant } from '../internalReducers/editedContentItemVariant.ts';
import { editedContentItemVariantComments } from '../internalReducers/editedContentItemVariantComments.ts';
import { editedContentItemVariantElements } from '../internalReducers/editedContentItemVariantElements.ts';
import { entryTimeline } from '../internalReducers/entryTimeline.ts';
import { firstIncompleteElementId } from '../internalReducers/firstIncompleteElementId.ts';
import { isCommentsPaneVisible } from '../internalReducers/isCommentsPaneVisible.ts';
import { isLoadMoreTimelineItemsInProgress } from '../internalReducers/isLoadMoreTimelineItemsInProgress.ts';
import { isVariantEditedForTheFirstTime } from '../internalReducers/isVariantEditedForTheFirstTime.ts';
import { itemValidationErrors } from '../internalReducers/itemValidationErrors.ts';
import { itemValidationFriendlyWarnings } from '../internalReducers/itemValidationFriendlyWarnings.ts';
import { itemValidationWarnings } from '../internalReducers/itemValidationWarnings.ts';
import { lastSelectedRevisionId } from '../internalReducers/lastSelectedRevisionId.ts';
import { loadedContentItemValidations } from '../internalReducers/loadedContentIemValidations.ts';
import { loadedContentItemTypes } from '../internalReducers/loadedContentItemTypes.ts';
import { loadedContentItems } from '../internalReducers/loadedContentItems.ts';
import { newContentItemVariantInit } from '../internalReducers/newContentItemVariantInit.ts';
import { previewConfiguration } from '../internalReducers/previewConfiguration.ts';
import { progressMessage } from '../internalReducers/progressMessage.ts';
import { selectedComparisonRevision } from '../internalReducers/selectedComparisonRevision.ts';
import { selectedComparisonRevisionElements } from '../internalReducers/selectedComparisonRevisionElements.ts';
import { selectedRevision } from '../internalReducers/selectedRevision.ts';
import { selectedRevisionElements } from '../internalReducers/selectedRevisionElements.ts';
import { showFriendlyWarnings } from '../internalReducers/showFriendlyWarnings.ts';
import { showIncomplete } from '../internalReducers/showIncomplete.ts';
import { showIncompleteDefaultVariantNotificationBar } from '../internalReducers/showIncompleteDefaultVariantNotificationBar.ts';
import { showIncompleteItemNotificationBar } from '../internalReducers/showIncompleteItemNotificationBar.ts';
import { showIncompleteItemWarningsBeforePublish } from '../internalReducers/showIncompleteItemWarningsBeforePublish.ts';
import { timelineContinuationToken } from '../internalReducers/timelineContinuatuonToken.ts';
import { variantTranslationTaskIdsInProgress } from '../internalReducers/variantTranslationTaskIdsInProgress.ts';
import { editorUi } from './editorUi.ts';

export const contentAppReducer = combineReducers({
  // new structure
  editorUi,
  listingUi,

  // the rest
  areContentItemElementsHighlighting,
  compareRevisions,
  contentItemVariants,
  changeWorkflowStepModalData,
  defaultVariantOverwritten,
  editedContentItem,
  editedContentItemOverwritten,
  editedContentItemStatus,
  editedContentItemVariant,
  editedContentItemVariantComments,
  editedContentItemVariantElements,
  entryTimeline,
  firstIncompleteElementId,
  isCommentsPaneVisible,
  isLoadMoreTimelineItemsInProgress,
  isVariantEditedForTheFirstTime,
  variantTranslationTaskIdsInProgress,
  itemValidationErrors,
  itemValidationFriendlyWarnings,
  itemValidationWarnings,
  lastSelectedRevisionId,
  loadedContentItems,
  loadedContentItemTypes,
  loadedContentItemValidations,
  newContentItem,
  newContentItemVariantInit,
  previewConfiguration,
  progressMessage,
  selectedComparisonRevision,
  selectedComparisonRevisionElements,
  selectedRevision,
  selectedRevisionElements,
  showFriendlyWarnings,
  showIncomplete,
  showIncompleteItemNotificationBar,
  showIncompleteItemWarningsBeforePublish,
  showIncompleteDefaultVariantNotificationBar,
  timelineContinuationToken,
});

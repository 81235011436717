import { forwardRef, useCallback } from 'react';
import { useHistory } from 'react-router';
import { IAnimatedModalDialogProps } from '../../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { DefaultVariantId } from '../../../../../_shared/constants/variantIdValues.ts';
import { useVariantTranslation } from '../../../../../_shared/features/AI/hooks/useVariantTranslation.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ContentItemEditingEventOrigins } from '../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { getCurrentUserId } from '../../../../../_shared/selectors/getCurrentUser.ts';
import { getSelectedLanguageId } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getSelectedLanguageNameOrPlaceholder } from '../../../../../_shared/selectors/getSelectedLanguageNameOrPlaceholder.ts';
import {
  getCurrentProject,
  getCurrentProjectId,
} from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { itemEditingModalDismissed } from '../../../actions/contentActions.ts';
import { getWorkflowsCurrentUserCanCreateVariantIn } from '../../../selectors/workflows/getWorkflowsCurrentUserCanCreateVariantIn.ts';
import { variantTranslationStarted } from '../../ContentItemEditing/actions/contentItemEditingActions.ts';
import { copyContentValuesFromDifferentExistingVariant } from '../../ContentItemEditing/actions/thunkContentItemEditingActions.ts';
import { getLanguageOptionsMemoized } from '../../ContentItemEditing/containers/selectors/copyContentFromVariantMenuOptions.ts';
import { submitNewVariantWorkflowSelection } from '../actions/thunks/thunkNewVariantActions.ts';
import {
  CreateNewVariant,
  NewVariantContentSource,
  NewVariantDialog as NewVariantDialogComponent,
} from '../components/NewVariantDialog.tsx';

export const NewVariantDialog = forwardRef<HTMLDivElement, IAnimatedModalDialogProps>((_, ref) => {
  const itemId = useSelector((s) => s.contentApp.editedContentItem?.id);
  const variantId = useSelector(getSelectedLanguageId) ?? DefaultVariantId;
  const projectId = useSelector(getCurrentProjectId);

  if (!itemId) {
    return null;
  }

  return (
    <NewVariantDialogContainer
      itemId={itemId}
      projectId={projectId}
      ref={ref}
      variantId={variantId}
    />
  );
});

type ContainerProps = {
  readonly itemId: Uuid;
  readonly variantId: Uuid;
  readonly projectId: Uuid;
};

const NewVariantDialogContainer = forwardRef<HTMLDivElement, ContainerProps>(
  ({ itemId, projectId, variantId }, ref) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const workflows = useSelector((state) =>
      getWorkflowsCurrentUserCanCreateVariantIn(
        state,
        getSelectedLanguageId(state) ?? DefaultVariantId,
        state.contentApp.editedContentItem?.collectionId ?? null,
        state.contentApp.editedContentItem?.editedContentItemTypeId ?? null,
      ),
    );

    const currentLanguageName = useSelector(getSelectedLanguageNameOrPlaceholder);
    const sourceLanguageOptions = useSelector((s) => {
      const selectedLanguageId = getSelectedLanguageId(s);
      const collectionId = s.contentApp.editedContentItem?.collectionId ?? null;

      return selectedLanguageId
        ? getLanguageOptionsMemoized(
            s.data.languages,
            selectedLanguageId,
            s.contentApp.contentItemVariants,
            s.data.user,
            getCurrentProject(s).projectId,
            collectionId,
          )
        : [];
    });

    const { startTranslation } = useVariantTranslation(projectId, itemId, variantId);

    const currentUserId = useSelector(getCurrentUserId);

    const onSubmit: CreateNewVariant = useCallback(
      async ({ selectedWorkflowId, contentSource, sourceLanguageId }) => {
        await dispatch(submitNewVariantWorkflowSelection(selectedWorkflowId, history));

        switch (contentSource) {
          case NewVariantContentSource.Empty:
            break;

          case NewVariantContentSource.CopyFromLanguage:
            await dispatch(
              copyContentValuesFromDifferentExistingVariant(
                history,
                sourceLanguageId,
                ContentItemEditingEventOrigins.QuickActions,
              ),
            );
            break;

          case NewVariantContentSource.TranslateWithAi: {
            await startTranslation(sourceLanguageId, currentUserId);
            dispatch(variantTranslationStarted(projectId, itemId, variantId));
            break;
          }
        }
      },
      [history, startTranslation, itemId, variantId, currentUserId, projectId],
    );

    const canGoBack = history.length > 1;

    const onCancel = useCallback(() => {
      dispatch(itemEditingModalDismissed());
      history.goBack();
    }, [history]);

    return (
      <NewVariantDialogComponent
        currentLanguageName={currentLanguageName}
        isDismissable={canGoBack}
        onSubmit={onSubmit}
        onCancel={canGoBack ? onCancel : undefined}
        ref={ref}
        sourceLanguageOptions={sourceLanguageOptions}
        workflows={workflows}
      />
    );
  },
);

NewVariantDialogContainer.displayName = 'NewVariantDialogContainer';

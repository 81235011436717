import { ReactNode, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { ContentItemsRouteParams } from '../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { getCurrentProjectId } from '../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { ISpace } from '../../../data/models/space/space.ts';
import { useSelectedItemEditorSpaceIdStorage } from '../../../localStorages/useSelectedItemEditorSpaceIdStorage.ts';
import { getUpdatedRoute } from '../features/ContentItemEditing/features/contentItemPreview/utils/getUpdatedRoute.ts';
import { getSpacesForPreviewUrls } from '../features/ContentItemEditing/utils/getAvailableSpacesForPreviewUrls.ts';

type Props = Readonly<{
  children: ReactNode;
}>;

export const RedirectIfSpaceIdInRouteNotValid = ({ children }: Props) => {
  const availableSpaces = useSelector(getSpacesForPreviewUrls);
  const history = useHistory();
  const { search } = useLocation();
  const currentSpaceId = useParams<ContentItemsRouteParams>().spaceId ?? null;
  const validatedCurrentSpaceId = validateSpaceId(currentSpaceId, availableSpaces);
  const environmentId = useSelector(getCurrentProjectId);
  const { spaceId: lastSelectedSpaceId } = useSelectedItemEditorSpaceIdStorage(environmentId);

  const newSpaceId = validatedCurrentSpaceId
    ? null
    : getNewSpaceId(lastSelectedSpaceId, availableSpaces);
  const updatedPath = newSpaceId
    ? getUpdatedRoute(history.location.pathname, null, newSpaceId)
    : null;

  useEffect(() => {
    if (updatedPath) {
      history.replace({
        pathname: updatedPath,
        search,
      });
    }
  }, [history, search, updatedPath]);

  return children;
};

const getNewSpaceId = (lastSelectedSpaceId: Uuid | null, availableSpaces: readonly ISpace[]) => {
  if (lastSelectedSpaceId && availableSpaces.find((s) => s.id === lastSelectedSpaceId)) {
    return lastSelectedSpaceId;
  }
  if (availableSpaces[0]) {
    return availableSpaces[0].id;
  }

  return null;
};

const validateSpaceId = (spaceId: Uuid | null, availableSpaces: readonly ISpace[]): Uuid | null => {
  if (spaceId && availableSpaces.find((s) => s.id === spaceId)) {
    return spaceId;
  }

  return null;
};

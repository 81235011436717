import { History } from 'history';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingTriggerDialogAction,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { initContentItemEditing } from '../../../ContentItemEditing/actions/thunkContentItemEditingActions.ts';
import {
  ContentItemEditing_NewVariant_Failed,
  ContentItemEditing_NewVariant_Finished,
  ContentItemEditing_NewVariant_Started,
} from '../../constants/newVariantActionTypes.ts';

const started = (workflowId: Uuid | null) =>
  ({
    type: ContentItemEditing_NewVariant_Started,
    payload: { workflowId },
  }) as const;

const finished = () =>
  ({
    type: ContentItemEditing_NewVariant_Finished,
  }) as const;

const failed = () =>
  ({
    type: ContentItemEditing_NewVariant_Failed,
  }) as const;

export type SubmitNewVariantWorkflowSelectionActions = ReturnType<
  typeof started | typeof finished | typeof failed
>;

export const createSubmitNewVariantWorkflowSelectionAction =
  () =>
  (selectedWorkflowId: Uuid | null, history: History): ThunkPromise =>
  async (dispatch) => {
    try {
      dispatch(started(selectedWorkflowId));
      await dispatch(initContentItemEditing(history, null));
      dispatch(
        trackUserEvent(TrackedEvent.ContentItemEditing, {
          action: ContentItemEditingTriggerDialogAction.NewVariantWorkflowSelected,
          origin: ContentItemEditingEventOrigins.NewVariantModal,
        }),
      );
      dispatch(finished());
    } catch (error) {
      dispatch(failed());
      throw error;
    }
  };

import { History } from 'history';
import { useContext } from 'react';
import { useHistory, useParams } from 'react-router';
import { ContentItemsRouteParams } from '../../../../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { getCurrentProjectId } from '../../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { useSelectedItemEditorSpaceIdStorage } from '../../../../../../../localStorages/useSelectedItemEditorSpaceIdStorage.ts';
import { ItemEditingNewDesignContext } from '../../../context/ItemEditingNewDesignContext.tsx';
import { getSpacesForPreviewUrls } from '../../../utils/getAvailableSpacesForPreviewUrls.ts';
import {
  OldSpaceSelector,
  SpaceSelector as SpaceSelectorComponent,
} from '../components/SpaceSelector.tsx';
import { getUpdatedRoute } from '../utils/getUpdatedRoute.ts';

export const SpaceSelector = () => {
  const availableSpaces = useSelector(getSpacesForPreviewUrls);
  const isNewDesign = useContext(ItemEditingNewDesignContext);
  const history = useHistory();
  const spaceId = useParams<ContentItemsRouteParams>().spaceId ?? null;
  const environmentId = useSelector(getCurrentProjectId);
  const { setSpaceId: setSpaceIdToStorage } = useSelectedItemEditorSpaceIdStorage(environmentId);

  const updateSpace = (newSpaceId: Uuid) => {
    const newRootItemId =
      availableSpaces.find((space) => space.id === newSpaceId)?.webSpotlightRootItemId ?? null;

    updateRoute(history, newRootItemId, newSpaceId);

    setSpaceIdToStorage(newSpaceId);
  };

  if (!availableSpaces.length) return null;

  const spaceMenuItems = availableSpaces.map((space) => ({
    id: space.id,
    label: space.name,
  }));

  if (!isNewDesign) {
    return (
      <OldSpaceSelector
        onSelectionChange={updateSpace}
        selectedSpaceId={spaceId}
        spaceMenuItems={spaceMenuItems}
      />
    );
  }

  return (
    <SpaceSelectorComponent
      onSelectionChange={updateSpace}
      selectedSpaceId={spaceId}
      spaceMenuItems={spaceMenuItems}
    />
  );
};

const updateRoute = (history: History, newRootItemId: Uuid | null, newSpaceId: Uuid): void => {
  const currentPath = history.location.pathname;
  const updatedRoute = getUpdatedRoute(currentPath, newRootItemId, newSpaceId);

  if (!updatedRoute) {
    return;
  }

  history.push(updatedRoute);
};
